$(function() {

    var filterBlock = $(".filter__block");
    
    filterBlock.each(function() {

        var items = $(this).find("ul li");

        items.on("click", function() {
            $(items, ".active").removeClass("active")
            $(this).addClass("active");
        })
    });


    //isotope
    // var iso = new Isotope( '.list-articles', {
    //     itemSelector: '.list-articles-item',
    //     layoutMode: 'fitRows'
    // });
  
    // // bind filter button click
    // var filtersElem = document.querySelector('.filter__block_list');
    // filtersElem.addEventListener( 'click', function( event ) {
    //     // only work with buttons
    //     if ( !matchesSelector( event.target, 'li' ) ) {
    //         return;
    //     }
    //     var filterValue = event.target.getAttribute('data-filter');
    //     iso.arrange({ filter: filterValue });
    // });

    // // change is-checked class on buttons
    // var buttonGroups = document.querySelectorAll('.filter__block_list');
    // for ( var i=0, len = buttonGroups.length; i < len; i++ ) {
    //     var buttonGroup = buttonGroups[i];
    //     radioButtonGroup( buttonGroup );
    // }

    // function radioButtonGroup( buttonGroup ) {
    //     buttonGroup.addEventListener( 'click', function( event ) {
    //         // only work with buttons
    //         if ( !matchesSelector( event.target, 'button' ) ) {
    //             return;
    //         }
    //         buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
    //         event.target.classList.add('is-checked');
    //     });
    // }
  
})